import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ApplicationState } from "../../../store";
import * as UserStore from "../../../store/user";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { Button } from "primereact/button";
import { IUser } from "../../../models/IUser";
import { Editor } from "primereact/editor";
import { InputSwitch } from "primereact/inputswitch";
import { AccountType } from "../../../models/enums/accountType";
import jwt_decode from "jwt-decode";
import { NavLink } from "reactstrap";
import { equal } from "assert";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";

type UserEditProps = UserStore.UserState & typeof UserStore.actionCreators & RouteComponentProps<{}>;

function UserEdit(props: UserEditProps) {
  const [currentUser, setCurrentUser] = useState(props.currentUser);
  const [employers, setEmployers] = useState([{ label: "Test", value: "" }]);
  const [districts, setDistricts] = useState([{ label: "Test", value: "" }]);
  const [schools, setSchools] = useState([{ label: "Test", value: "" }]);
  const [currentUserId, setCurrentUserId] = useState(null);

    useEffect(() => {
        if (localStorage.getItem("token")) {
            setCurrentUserId((jwt_decode(localStorage.getItem("token") as string) as any).UserId);
        }
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const selectedUserId = (props.match.params as any).userId;
            await Promise.all([
                props.getEmployers(),
                props.getUser(selectedUserId),
                props.getDistricts(),
                props.getSchools()
            ]);
        };

        fetchData();
    }, []);


    useEffect(() => {
        if (props.selectedUser) {
            setCurrentUser(props.selectedUser as IUser);
        }
    }, [props.selectedUser]);

    useEffect(() => {
        if (props.employerList) {
            const mappedEmployers = props.employerList.map((employer: any) => ({
                label: employer.employerName,
                value: employer.id.toString()
            }));
            setEmployers(mappedEmployers);
        }
    }, [props.employerList]);

    useEffect(() => {
        if (props.districtList) {
            const mappedDistricts = props.districtList.map((district: any) => ({
                label: district.districtName,
                value: district.id.toString()
            }));
            setDistricts(mappedDistricts);
        }
    }, [props.districtList]);

    useEffect(() => {
        if (props.schoolList) {
            const mappedSchools = props.schoolList.map((school: any) => ({
                label: school.schoolName,
                value: school.id.toString()
            }));
            setSchools(mappedSchools);
        }
    }, [props.schoolList]);


  const notify: any = () => toast.success("Settings Saved!");

  return (
    <React.Fragment>
      <Card title="Edit User" subTitle="">
        <label>Username:</label>
        <br />
        <InputText
          disabled={(jwt_decode(localStorage.getItem("token") as string) as any).AccountType == AccountType.District}
          name="username"
          value={currentUser?.username}
          onChange={(e) => setCurrentUser({ ...currentUser, username: (e.target as unknown as HTMLTextAreaElement).value })}
        />
        <br />
        <br />

        <label>First Name:</label>
        <br />
        <InputText
          disabled={(jwt_decode(localStorage.getItem("token") as string) as any).AccountType == AccountType.District}
          name="username"
          value={currentUser?.firstName}
          onChange={(e) => setCurrentUser({ ...currentUser, firstName: (e.target as unknown as HTMLTextAreaElement).value })}
        />
        <br />
        <br />

        <label>Last Name:</label>
        <br />
        <InputText
          disabled={(jwt_decode(localStorage.getItem("token") as string) as any).AccountType == AccountType.District}
          name="username"
          value={currentUser?.lastName}
          onChange={(e) => setCurrentUser({ ...currentUser, lastName: (e.target as unknown as HTMLTextAreaElement).value })}
        />
        <br />
        <br />

        <label>Email:</label>
        <br />
        <InputText
          disabled={(jwt_decode(localStorage.getItem("token") as string) as any).AccountType == AccountType.District}
          name="username"
          value={currentUser?.email}
          onChange={(e) => setCurrentUser({ ...currentUser, email: (e.target as unknown as HTMLTextAreaElement).value })}
        />
        <br />
        <br />

        <label>Phone:</label>
        <br />
        <InputText
          disabled={(jwt_decode(localStorage.getItem("token") as string) as any).AccountType == AccountType.District}
          name="username"
          value={currentUser?.phone}
          onChange={(e) => setCurrentUser({ ...currentUser, phone: (e.target as unknown as HTMLTextAreaElement).value })}
        />
        <br />
        <br />

        {currentUser?.accountType == AccountType.Employer ? (
          <div>
            <label>Employer:</label>
            <br />
            <Dropdown
              disabled={(jwt_decode(localStorage.getItem("token") as string) as any).AccountType == AccountType.District}
              value={currentUser?.employerId ? currentUser?.employerId.toString() : 0}
              options={employers}
              onChange={(e) => {
                setCurrentUser({ ...currentUser, employerId: e.value });
              }}
              placeholder="Select An Employer"
            />
            <br />
            <br />
          </div>
        ) : (
          ""
              )}       

              {currentUser?.accountType == AccountType.District && currentUserId === currentUser.id.toString() ? (
                  <div>
                      <label>Schools:</label>
                      <br />
                      <MultiSelect
                          value={currentUser.schoolIdsForComparison}
                          options={schools}
                          onChange={(e) => {
                              setCurrentUser({ ...currentUser, schoolIdsForComparison: e.value });
                          }}
                          placeholder="Select Schools"
                      />
                      <br />
                      <br />
                  </div>
              ) : (
                  ""
              )}

        {currentUser?.accountType == AccountType.District && (jwt_decode(localStorage.getItem("token") as string) as any).AccountType == AccountType.Admin ? (
          <div>
            <label>District:</label>
            <br />
            <Dropdown
              disabled={(jwt_decode(localStorage.getItem("token") as string) as any).AccountType == AccountType.District}
              value={currentUser?.districtId ? currentUser?.districtId.toString() : 0}
              options={districts}
              onChange={(e) => {
                setCurrentUser({ ...currentUser, districtId: e.value });
              }}
              placeholder="Select A District"
            />
            <br />
            <br />
          </div>
        ) : (
          ""
        )}

        {(jwt_decode(localStorage.getItem("token") as string) as any).AccountType == AccountType.Admin ? (
          <div>
            <label>Approved:</label>
            <br />
            <InputSwitch
              disabled={(jwt_decode(localStorage.getItem("token") as string) as any).AccountType == AccountType.District}
              checked={currentUser?.approved}
              onChange={(e) => setCurrentUser({ ...currentUser, approved: e.value })}
            />
            <br />
            <br />
          </div>
        ) : (
          ""
        )}

              <Button
          disabled={currentUserId != currentUser.id.toString() && (jwt_decode(localStorage.getItem("token") as string) as any).AccountType != AccountType.Admin}
          label="Save"
          onClick={() => {
            props.updateUser(currentUser as IUser);
            notify();
          }}
        />
        {currentUser?.id > 0 && (
          <Button
            disabled={(jwt_decode(localStorage.getItem("token") as string) as any).AccountType == AccountType.District}
            label="Archive"
            className="p-button-danger"
            onClick={() => {
              props.deleteUser(currentUser?.id);
              props.history.push(`/users`);
            }}
          />
        )}
      </Card>
    </React.Fragment>
  );
}

export default connect((state: ApplicationState) => state.user, UserStore.actionCreators)(UserEdit as any);
