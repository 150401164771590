import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../../../store';
import * as EmployerStore from '../../../store/employer';
import * as UserStore from '../../../store/user';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';

type EmployerListProps =
    EmployerStore.EmployerState & UserStore.UserState &
    typeof EmployerStore.actionCreators & typeof UserStore.actionCreators &
    RouteComponentProps<{}>;

class EmployerList extends React.PureComponent<EmployerListProps, any> {
    dt: DataTable | null | undefined;

    constructor(props: any) {
        super(props);

        this.state = {

        }

        this.onExport = this.onExport.bind(this);
    }

    componentDidMount() {
        this.props.getEmployers();
    }

    autoApproveNewUsersTemplate(rowData: any) {
        let value = rowData.autoApproveNewUsers as boolean;

        if (value) {
            return <span>Yes</span>;
        } else {
            return <span>No</span>;
        }
    }

    autoApproveNewInternshipsTemplate(rowData: any) {
        let value = rowData.autoApproveNewInternships as boolean;

        if (value) {
            return <span>Yes</span>;
        } else {
            return <span>No</span>;
        }
    }

    onExport() {
        if (this.dt) this.dt.exportCSV({selectionOnly: false});
    }

    public render() {
        const header = (
            <div style={{ 'textAlign': 'left' }}>
                <i className="pi pi-search" style={{ margin: '4px 4px 0 0' }}></i>
                <InputText type="search" onInput={(e) => this.setState({ globalFilter: (e.target as HTMLTextAreaElement).value })} placeholder="Global Search" size={50} />
                <Button className="float-right" type="button" icon="pi pi-external-link" iconPos="left" label="CSV" onClick={this.onExport}></Button>
            </div>
        );

        return (
            <React.Fragment>
                <h1>Employers</h1>
                <Button label="Add" icon="pi pi-plus" onClick={$event => this.handleAddClick()} /><br /><br />

                <DataTable
                    ref={(el) => this.dt = el}
                    value={this.props.employerList}
                    onRowClick={$event => this.handleRowClick($event)}
                    selectionMode="single"
                    header={header}
                    globalFilter={this.state.globalFilter}
                >
                    <Column style={{ width: '120px' }} field="employerName" header="Employer" sortable={true} />
                    <Column style={{ width: '120px' }} field="mainContactName" header="Main Contact" sortable={true} />
                    <Column style={{ width: '220px' }} field="mainContactEmail" header="Email" sortable={true} />
                    <Column style={{ width: '100px' }} field="mainContactPhone" header="Phone" sortable={true} />
                    {/*<Column style={{ width: '100px' }} field="autoApproveNewUsers" header="Auto Approve New Users" sortable={true} body={this.autoApproveNewUsersTemplate} />*/}
                    {/*<Column style={{ width: '100px' }} field="autoApproveNewInternships" header="Auto Approve Internships" sortable={true} body={this.autoApproveNewInternshipsTemplate} />*/}

                </DataTable>
            </React.Fragment>
        );
    }

    handleRowClick(e: any) {
        this.props.history.push(`/employers/${e.data.id}`);
    }

    handleAddClick() {
        this.props.history.push(`/employers/0`);
    }
};

export default connect(
    (state: ApplicationState) => state.employer,
    EmployerStore.actionCreators
)(EmployerList as any);
