import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ApplicationState } from "../../store";
import * as UserStore from "../../store/user";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Password } from "primereact/password";
import { Link } from "react-router-dom";

type UserProps = UserStore.UserState & typeof UserStore.actionCreators & RouteComponentProps<{}>;

class Login extends React.PureComponent<UserProps> {
  public render() {
    // if (window.caches.keys.length > 0) {
    //   window.caches.keys().then(function (names) {
    //     for (let name of names) caches.delete(name);
    //   });

    //   // delete browser cache and hard reload
    //   window.location.reload();
    // }

    return (
      <React.Fragment>
        <Card title="Login" subTitle="">
          <form
            onSubmit={(event) => {
              event.preventDefault();
              this.props.requestAuthToken(this.props.username, this.props.password);
            }}
          >
            <label>Username:</label>
            <br />
            <InputText
              name="username"
              value={this.props.username}
              onChange={(e) => this.props.updateUsername((e.target as unknown as HTMLTextAreaElement).value)}
            />
            <br />
            <br />
            <label>Password:</label>
            <br />
            <Password
              feedback={false}
              value={this.props.password}
              onChange={(e) => this.props.updatePassword((e.target as unknown as HTMLTextAreaElement).value)}
            />
            <br />
            <br />
            <button className={"p-button p-component p-button-text-only"}>
              <span className={"p-button-text p-c"}>Log In</span>
            </button>
            <br />
            <br />
            Or
            <br />
            <br />
            <Button
              label="Sign Up"
              onClick={() => {
                this.props.logout();
                this.props.history.push("/signup");
              }}
            />
            <br />
            <br />
            <Link to={"/forgotPassword"}>Forgot Password</Link>
            <br />
            <br />
            <br />
            <p className="errorText">{this.props.loginErrorMessage}</p>
            <br />
            <br />
            <p>
              If you are experiencing technical difficulties, please contact Bayly Wise at{" "}
              <a href="mailto:bwise@avescholars.org">bwise@avescholars.org</a>.
            </p>
          </form>
        </Card>
      </React.Fragment>
    );
  }
}

export default connect((state: ApplicationState) => state.user, UserStore.actionCreators)(Login as any);
