import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ApplicationState } from "../../../store";
import * as UserStore from "../../../store/user";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import jwt_decode from "jwt-decode";
import { useEffect, useState } from "react";

type UserListProps = UserStore.UserState & UserStore.UserState & typeof UserStore.actionCreators & typeof UserStore.actionCreators & RouteComponentProps<{}>;

function UserList(props: UserListProps) {
  const [cohortList, setCohortList] = useState([{ label: "Test", value: "" }]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [selectedDomain, setSelectedDomain] = useState("");
  const [selectedCohorts, setSelectedCohorts] = useState();
  const [domains, setDomains] = useState([
    { label: "Yes", value: "true" },
    { label: "No", value: "false" },
  ]);

  useEffect(() => {
    props.getCohorts();
    props.getUsers();
  }, []);

  useEffect(() => {
    const cohorts = props.cohortList.map((cohort) => {
      return { label: cohort.name, value: cohort.id.toString() };
    });
    setCohortList(cohorts);
  }, [props.cohortList]);

  let dt: DataTable | null | undefined;

  function onDomainChange(event: any) {
    if (dt) {
      dt.filter(event.value, "approved", "equals");
      setSelectedDomain(event.value);
    }

  }

  function onCohortChange(event: any) {
    if (dt) {
      dt.filter(event.value, 'student.cohortId', 'equals');
      setSelectedCohorts(event.value);
    }
  }

  function onExport() {
    if (dt) dt.exportCSV({ selectionOnly: false });
  }




  function accountTypeTemplate(rowData: any) {
    if (rowData.accountType === 0) {
      return "Admin";
    } else if (rowData.accountType === 1) {
      if (rowData.employer) {
        return (
          <div>
            Employer <br />
            {rowData.employer.employerName}
          </div>
        );
      } else {
        return "Employer - Pending";
      }
    } else if (rowData.accountType === 2) {
      if (rowData.student) {
        return (
          <div>
            Student <br />
            {rowData.student.highSchool}
          </div>
        );
      }
    } else if (rowData.accountType === 3) {
      if (rowData.district) {
        return (
          <div>
            District Admin <br />
            {rowData.district.districtName}
          </div>
        );
      }
    }
  }

  function isApprovedTemplate(rowData: any) {
    let value = rowData.approved as boolean;

    if (value) {
      return <span>Yes</span>;
    } else {
      return <span>No</span>;
    }
  }

  function formatDate(rowData: any) {
    let value = new Date(rowData.created);

    return value.toLocaleString();
  }

  function nameTemplate(rowData: any) {
    return `${rowData.lastName}, ${rowData.firstName}`;
  }

  function handleRowClick(e: any) {
    props.history.push(`/users/edit/${e.data.id}`);
  }

  function handleAddClick() {
    props.history.push(`/users/0`);
  }

  const header = (
    <div style={{ textAlign: "left" }}>
      <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i>
      <InputText
        type="search"
        onInput={(e) => setGlobalFilter((e.target as HTMLTextAreaElement).value)}
        placeholder="Global Search"
        size={50}
      />
      <Button className="float-right" type="button" icon="pi pi-external-link" iconPos="left" label="CSV" onClick={onExport}></Button>
      <Dropdown style={{ width: '200px' }} placeholder="Select a Cohort" value={selectedCohorts} options={cohortList} onChange={onCohortChange} showClear />
    </div>
  );

  const domainFilter = (
    <Dropdown
      style={{ width: "100%" }}
      placeholder="Select a value"
      value={selectedDomain}
      options={domains}
      onChange={onDomainChange}
      showClear
    />
  );

  const paginatorLeft = <Button icon="pi pi-refresh" />;

  const currentType = (jwt_decode(localStorage.getItem("token") as string) as any).AccountType;

  return (
    <React.Fragment>
      <h1>Users</h1>
      {currentType === "2" && (
        <div>
          <Button label="Add" icon="pi pi-plus" onClick={($event) => handleAddClick()} />
          <br />
          <br />
        </div>
      )}

      <DataTable
        ref={(el) => (dt = el)}
        value={props.userList}
        onRowClick={($event) => handleRowClick($event)}
        selectionMode="single"
        header={header}
        globalFilter={globalFilter}
        paginator={true}
        paginatorLeft={paginatorLeft}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        rows={20}
        rowsPerPageOptions={[5, 10, 20, 50]}
      >
        <Column field="username" header="Username" sortable={true} />
        <Column field="lastName" header="Name" sortable={true} body={nameTemplate} />
        <Column field="firstName" header="Name" sortable={true} style={{ display: "none" }} />
        <Column field="email" header="email" sortable={true} style={{ width: "220px" }} />
        <Column field="accountType" header="Type" sortable={true} body={accountTypeTemplate} style={{ minwidth: "140px" }} />
        <Column
          field="approved"
          header="Approved"
          sortable={true}
          body={isApprovedTemplate}
          style={{ width: "200px" }}
          filter={true}
          filterElement={domainFilter}
        />
        <Column field="created" header="Created" sortable={true} body={formatDate} style={{ width: "100px" }} />
        <Column field="employer.employerName" header="Employer Name" sortable={true} style={{ display: "none" }} />
        <Column field="student.highSchool" header="High School" sortable={true} style={{ display: "none" }} />
        <Column field="student.cohortId" header="Cohort Id" sortable={true} style={{ display: "none" }} />
      </DataTable>
    </React.Fragment>
  );
}

export default connect((state: ApplicationState) => state.user, UserStore.actionCreators)(UserList as any);