import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import { ITokenResult } from "../models/ITokenResult";
import { IEmployer } from "../models/IEmployer";
import { push } from "react-router-redux";
import { RouterAction } from "connected-react-router";

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface EmployerState {
  employerList: Array<IEmployer>;
  currentEmployer?: IEmployer;
  //imageUrl: string,
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface CreateEmployerAction {
  type: "CREATE_EMPLOYER_BEGIN";
  createEmployer: IEmployer;
}
export interface CreateEmployerSuccessAction {
  type: "CREATE_EMPLOYER_SUCCESS";
}
export interface CreateEmployerErrorAction {
  type: "CREATE_EMPLOYER_ERROR";
}

export interface UpdateEmployerAction {
  type: "UPDATE_EMPLOYER_BEGIN";
  updateEmployer: IEmployer;
}
export interface UpdateEmployerSuccessAction {
  type: "UPDATE_EMPLOYER_SUCCESS";
}
export interface UpdateEmployerErrorAction {
  type: "UPDATE_EMPLOYER_ERROR";
}

export interface GetEmployersAction {
  type: "GET_EMPLOYERS_BEGIN";
}
export interface GetEmployersSuccessAction {
  type: "GET_EMPLOYERS_SUCCESS";
  data: Array<IEmployer>;
}
export interface GetEmployersErrorAction {
  type: "GET_EMPLOYERS_ERROR";
}

export interface GetEmployerAction {
  type: "GET_EMPLOYER_BEGIN";
  employerId: number;
}
export interface GetEmployerSuccessAction {
  type: "GET_EMPLOYER_SUCCESS";
  data: IEmployer;
}
export interface GetEmployerErrorAction {
  type: "GET_EMPLOYER_ERROR";
}

export interface DeleteEmployerAction {
  type: "DELETE_EMPLOYER_BEGIN";
  employerId: number;
}
export interface DeleteEmployerSuccessAction {
  type: "DELETE_EMPLOYER_SUCCESS";
  data: boolean;
}
export interface DeleteEmployerErrorAction {
  type: "DELETE_EMPLOYER_ERROR";
}

export interface ClearCurrentEmployer {
  type: "CLEAR_CURRENT_EMPLOYER";
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction =
  | CreateEmployerAction
  | CreateEmployerSuccessAction
  | CreateEmployerErrorAction
  | UpdateEmployerAction
  | UpdateEmployerSuccessAction
  | UpdateEmployerErrorAction
  | GetEmployersAction
  | GetEmployersSuccessAction
  | GetEmployersErrorAction
  | GetEmployerAction
  | GetEmployerSuccessAction
  | GetEmployerErrorAction
  | DeleteEmployerAction
  | DeleteEmployerSuccessAction
  | DeleteEmployerErrorAction
  | ClearCurrentEmployer
  | RouterAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
  // createEmployer: (createEmployer: ICreateEmployer) => ({ type: 'CREATE_EMPLOYER_BEGIN', createEmployer: createEmployer } as CreateEmployerAction),
  createEmployerSuccess: () => ({ type: "CREATE_EMPLOYER_SUCCESS" } as CreateEmployerSuccessAction),
  createEmployerError: () => ({ type: "CREATE_EMPLOYER_ERROR" } as CreateEmployerErrorAction),
  createEmployer:
    (createEmployer: IEmployer): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();
      if (appState) {
        fetch(`api/employers`, {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(createEmployer),
        })
          .then((response) => {
            var data = response.json() as Promise<ITokenResult>;
            return data;
          })
          .then(() => {
            dispatch({ type: "CREATE_EMPLOYER_SUCCESS" });
            dispatch(push("/employers"));
          });

        dispatch({ type: "CREATE_EMPLOYER_BEGIN", createEmployer });
      }
    },
  updateEmployerSuccess: () => ({ type: "UPDATE_EMPLOYER_SUCCESS" } as UpdateEmployerSuccessAction),
  updateEmployerError: () => ({ type: "UPDATE_EMPLOYER_ERROR" } as UpdateEmployerErrorAction),
  updateEmployer:
    (updateEmployer: IEmployer): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();
      if (appState) {
        fetch(`api/employers`, {
          method: "put",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(updateEmployer),
        })
          .then((response) => {
            var data = response.json() as Promise<ITokenResult>;
            return data;
          })
          .then(() => {
            dispatch({ type: "UPDATE_EMPLOYER_SUCCESS" });
            //dispatch(push(`/employers`));
          });

        dispatch({ type: "UPDATE_EMPLOYER_BEGIN", updateEmployer });
      }
    },
  getEmployers: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.employer) {
      fetch(`api/employers`, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        //body: JSON.stringify(createEmployer)
      })
        .then((response) => {
          var data = response.json() as Promise<Array<IEmployer>>;
          return data;
        })
        .then((data) => {
          dispatch({ type: "GET_EMPLOYERS_SUCCESS", data });
        });

      dispatch({ type: "GET_EMPLOYERS_BEGIN" });
    }
  },
  getEmployersSuccess: (data: any) => ({ type: "GET_EMPLOYERS_SUCCESS", data: data } as GetEmployersSuccessAction),
  getEmployer:
    (employerId: number): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();
      if (appState && appState.employer) {
        fetch(`api/employers/${employerId}`, {
          method: "get",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          //body: JSON.stringify(createEmployer)
        })
          .then((response) => {
            var data = response.json() as Promise<IEmployer>;
            return data;
          })
          .then((data) => {
            dispatch({ type: "GET_EMPLOYER_SUCCESS", data });
          });

        dispatch({ type: "GET_EMPLOYER_BEGIN", employerId });
      }
    },
  getEmployerSuccess: (data: any) => ({ type: "GET_EMPLOYER_SUCCESS", data: data } as GetEmployerSuccessAction),
  deleteEmployer:
    (employerId: number): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();
      if (appState && appState.employer) {
        fetch(`api/employers/${employerId}`, {
          method: "delete",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          //body: JSON.stringify(createEmployer)
        })
          .then((response) => {
            var data = response.json() as Promise<boolean>;
            return data;
          })
          .then((data) => {
            dispatch({ type: "DELETE_EMPLOYER_SUCCESS", data });
          });

        dispatch({ type: "DELETE_EMPLOYER_BEGIN", employerId });
      }
    },
  deleteEmployerSuccess: (data: any) => ({ type: "DELETE_EMPLOYER_SUCCESS", data: data } as DeleteEmployerSuccessAction),
  clearCurrentEmployer: () => ({ type: "CLEAR_CURRENT_EMPLOYER" } as ClearCurrentEmployer),
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<EmployerState> = (state: EmployerState | undefined, incomingAction: Action): EmployerState => {
  if (state === undefined) {
    return {
      employerList: [],
      currentEmployer: undefined,
    };
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    case "CREATE_EMPLOYER_BEGIN":
      return {
        ...state,
      };
    case "GET_EMPLOYERS_SUCCESS":
      return {
        ...state,
        employerList: (action as GetEmployersSuccessAction).data,
      };
    case "GET_EMPLOYER_SUCCESS":
      return {
        ...state,
        currentEmployer: (action as GetEmployerSuccessAction).data,
      };
    case "DELETE_EMPLOYER_SUCCESS":
      return {
        ...state,
        currentEmployer: undefined,
      };
    case "CLEAR_CURRENT_EMPLOYER":
      return {
        ...state,
        currentEmployer: {
          employerName: "",
          employerLogo: "",
          website: "",
          mainContactName: "",
          mainContactPhone: "",
          mainContactEmail: "",
          autoApproveEmailDomain: "",
          autoApproveNewUsers: false,
          autoApproveNewInternships: false,
          address1: "",
          address2: "",
          city: "",
          state: "",
          zip: "",
          id: 0,
          created: new Date("1/1/2020"),
          updated: new Date("1/1/2020"),
        },
      };
    default:
      return state;
  }
};
