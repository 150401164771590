import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ApplicationState } from "../../../store";
import * as AdminSettingsStore from "../../../store/adminSettings";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { IAdminSettings } from "../../../models/IAdminSettings";
import { Card } from "primereact/card";
import { ToastContainer, toast } from "react-toastify";
import { FileUpload } from "primereact/fileupload";
import "react-toastify/dist/ReactToastify.css";
import { Calendar } from "primereact/calendar";
import { Editor } from "primereact/editor";

type AdminSettingsProps = AdminSettingsStore.AdminSettingsState & typeof AdminSettingsStore.actionCreators & RouteComponentProps<{}>;

class AdminSettings extends React.PureComponent<AdminSettingsProps, IAdminSettings> {
  constructor(props: any) {
    super(props);

    this.state = {
      id: 0,
      created: new Date("1/1/2020"),
      updated: new Date("1/1/2020"),
      studentHomeScreenContent: "",
      adminHomeScreenContent: "",
      employerHomeScreenContent: "",
      districtHomeScreenContent: "",
      applicationOpenDate: new Date(),
      applicationCloseDate: new Date(),
    } as IAdminSettings;
  }

  componentDidMount() {
    this.props.getAdminSettings(1);
    setTimeout(() => {
      this.setState(this.props.currentAdminSettings as IAdminSettings);
    }, 250);
  }

  notify: any = () => toast.success("Settings Saved!");

  public render() {
    return (
      <React.Fragment>
        {/*<Button*/}
        {/*  label="Email Students"*/}
        {/*  onClick={() => {*/}
        {/*    this.props.history.push("/adminsettings/studentemailblast");*/}
        {/*  }}*/}
        {/*/>*/}
        &nbsp;
        {/*<Button*/}
        {/*  label="Email Employers"*/}
        {/*  onClick={() => {*/}
        {/*    this.props.history.push("/adminsettings/employeremailblast");*/}
        {/*  }}*/}
        {/*/>*/}
        &nbsp;
        <Button
          label="Email Students Who Haven't Submitted"
          onClick={() => {
            this.props.triggerEmailStudentsNotSubmitted();
          }}
        />
        &nbsp;
        <Button
          label="Email All Students Of Closing Window"
          onClick={() => {
            this.props.triggerEmailStudentsWindowClosing();
          }}
        />
        <br />
        <br />
        <br />
        <Card title="Admin Settings" subTitle="">
          <label>Application Period Open Date:</label>
          <br />
          <Calendar
            monthNavigator={true}
            showTime={false}
            dateFormat="mm/dd/yy"
            value={this.state.applicationOpenDate}
            onChange={(e) => this.setState({ applicationOpenDate: e.value as Date })}
          ></Calendar>
          <br />
          <br />

          <label>Application Period Close Date:</label>
          <br />
          <Calendar
            monthNavigator={true}
            showTime={false}
            dateFormat="mm/dd/yy"
            value={this.state.applicationCloseDate}
            onChange={(e) => this.setState({ applicationCloseDate: e.value as Date })}
          ></Calendar>
          <br />
          <br />

          <label>Admin Homepage Content:</label>
          <br />
          <Editor
            style={{ height: "320px" }}
            value={this.state.adminHomeScreenContent}
            onTextChange={(e) => this.setState({ adminHomeScreenContent: e.htmlValue as string })}
          />
          <br />

          <label>Employer Homepage Content:</label>
          <br />
          <Editor
            style={{ height: "320px" }}
            value={this.state.employerHomeScreenContent}
            onTextChange={(e) => this.setState({ employerHomeScreenContent: e.htmlValue as string })}
          />
          <br />

          <label>Student Homepage Content:</label>
          <br />
          <Editor
            style={{ height: "320px" }}
            value={this.state.studentHomeScreenContent}
            onTextChange={(e) => this.setState({ studentHomeScreenContent: e.htmlValue as string })}
          />
          <br />

          <label>District Admin Homepage Content:</label>
          <br />
          <Editor
            style={{ height: "320px" }}
            value={this.state.districtHomeScreenContent}
            onTextChange={(e) => this.setState({ districtHomeScreenContent: e.htmlValue as string })}
          />
          <br />

          <Button
            label="Save"
            onClick={() => {
              this.props.updateAdminSettings(this.state);
              this.notify();
            }}
          />
        </Card>
      </React.Fragment>
    );
  }
}

export default connect((state: ApplicationState) => state.adminSettings, AdminSettingsStore.actionCreators)(AdminSettings as any);
