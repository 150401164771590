import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ApplicationState } from "../../../store";
import * as InternshipStore from "../../../store/internship";
import * as ApplicationsStore from "../../../store/application";
import * as UserStore from "../../../store/user";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { IInternship } from "../../../models/IInternship";
import { Editor } from "primereact/editor";
import { InputSwitch } from "primereact/inputswitch";
import { AccountType } from "../../../models/enums/accountType";
import jwt_decode from "jwt-decode";
import { IApplication } from "../../../models/IApplication";
import { MultiSelect } from "primereact/multiselect";
import { RadioButton } from "primereact/radiobutton";

type InternshipApplyProps = ApplicationsStore.ApplicationsState & typeof ApplicationsStore.actionCreators & RouteComponentProps<{}>;

class InternshipApply extends React.PureComponent<InternshipApplyProps, IApplication> {
  constructor(props: any) {
    super(props);
    this.myRef = React.createRef();

    this.state = {
      id: 0,
      studentId: 0,
      isButtonClicked: false,
      student: {
        id: 0,
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        schoolDistrict: "",
        highSchool: "",
        hasResume: false,
        gender: "",
        race: "",
      },
      internshipId: 0,
      internship: {
        employerId: 0,
        employer: undefined,
        id: 0,
        created: new Date("1/1/2020"),
        updated: new Date("1/1/2020"),
        internshipTitle: "",
        internshipDomain: "",
        jobDescription: "",
        eligibilityRequirements: "",
        preferredSkills: "",
        workSchedule: "",
        dressCodeRequirements: "",
        requiredTools: "",
        payPerHour: 0,
        payPerHourMax: 0,
        numberOfPositionsAvailable: 1,
        internshipLocationAddress1: "",
        internshipLocationAddress2: "",
        internshipLocationCity: "",
        internshipLocationState: "",
        internshipLocationZip: "",
        isApproved: false,
        isPublished: false,
        alreadyApplied: false,
      },
      howDidYouHear: "",
      whatInterestsYou: "",
      whyStrongestCandidate: "",
      immediateCareerGoals: "",
      longTermCareerGoals: "",
      canSatisfyRequirements: undefined,
      canSatisfyRequirementsExplaination: "",
      howDidYouHearOther: "",
      applicationStatus: 10,
    } as IApplication;

    this.internship = {
      employerId: 0,
      employer: undefined,
      id: 0,
      created: new Date("1/1/2020"),
      updated: new Date("1/1/2020"),
      internshipTitle: "",
      internshipDomain: "",
      jobDescription: "",
      eligibilityRequirements: "",
      preferredSkills: "",
      workSchedule: "",
      dressCodeRequirements: "",
      requiredTools: "",
      payPerHour: 0,
      numberOfPositionsAvailable: 1,
      internshipLocationAddress1: "",
      internshipLocationAddress2: "",
      internshipLocationCity: "",
      internshipLocationState: "",
      internshipLocationZip: "",
      isApproved: false,
      isPublished: false,
      alreadyApplied: false,
    } as IInternship;
  }

  private myRef: any;
  private internship: IInternship;

  componentDidMount() {
    const currentInternshipId = (this.props.match.params as any).internshipId;
    const currentApplicationId = (this.props.match.params as any).applicationId;

    if (currentInternshipId > 0) {
      this.props.getInternship(currentInternshipId);

      if (currentApplicationId > 0) {
        this.props.getApplication(currentApplicationId);
      }

      setTimeout(() => {
        if (currentApplicationId > 0) {
          this.setState({
            id: this.props.currentApplication?.id,
            howDidYouHearOther: this.props.currentApplication?.howDidYouHearOther,
            whatInterestsYou: this.props.currentApplication?.whatInterestsYou,
            whyStrongestCandidate: this.props.currentApplication?.whyStrongestCandidate,
            immediateCareerGoals: this.props.currentApplication?.immediateCareerGoals,
            longTermCareerGoals: this.props.currentApplication?.longTermCareerGoals,
            canSatisfyRequirementsExplaination: this.props.currentApplication?.canSatisfyRequirementsExplaination,
            canSatisfyRequirements: this.props.currentApplication?.canSatisfyRequirements,
            // howDidYouHear: this.props.currentApplication?.howDidYouHear,
            internship: this.props.currentInternship,
            internshipId: currentInternshipId,
            studentId: (jwt_decode(localStorage.getItem("token") as string) as any).StudentId,
          } as IApplication);
        } else {
          this.setState({
            internship: this.props.currentInternship,
            internshipId: currentInternshipId,
            studentId: (jwt_decode(localStorage.getItem("token") as string) as any).StudentId,
          });
        }

        // this.setState({ howDidYouHear: this.props.currentApplication?.howDidYouHear ?? "" as string });
      }, 250);
    } else {
      //this.props.clearCurrentInternship();
      this.setState({
        id: 0,
        studentId: 0,
        student: {
          id: 0,
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          schoolDistrict: "",
          highSchool: "",
          hasResume: false,
          gender: "",
          race: "",
          isAvenueScholar: false,
          isMCCCareerAcademy: false,
          graduationYear: 2021,
          freeOrReducedLunch: "No",
          isInterestedInMCC: false,
          resumeFilename: "",
          schoolId: 0,
          cohortId: 0,
        },
        internshipId: 0,
        internship: {
          employerId: 0,
          employer: undefined,
          id: 0,
          created: new Date("1/1/2020"),
          updated: new Date("1/1/2020"),
          internshipTitle: "",
          internshipDomain: "",
          jobDescription: "",
          eligibilityRequirements: "",
          preferredSkills: "",
          workSchedule: "",
          dressCodeRequirements: "",
          requiredTools: "",
          payPerHour: 0,
          payPerHourMax: 0,
          numberOfPositionsAvailable: 1,
          internshipLocationAddress1: "",
          internshipLocationAddress2: "",
          internshipLocationCity: "",
          internshipLocationState: "",
          internshipLocationZip: "",
          isApproved: false,
          isPublished: false,
          alreadyApplied: false,
          profileComplete: false,
          externalJobApplicationLink: "",
          youTubeVideoLink: "",
          selectedCohortIds: [],
          whoWeAre: "",
          whatYouCanExpect: "",
          summerOnly: false,
          internshipLength: ""
        },
        howDidYouHear: "",
        whatInterestsYou: "",
        whyStrongestCandidate: "",
        immediateCareerGoals: "",
        longTermCareerGoals: "",
        canSatisfyRequirements: true,
        canSatisfyRequirementsExplaination: "",
        howDidYouHearOther: "",
      });
    }
  }

  public render() {
    const domains = [
      { label: "Automotive & Diesel", value: "Automotive & Diesel" },
      { label: "Business", value: "Business" },
      // { label: "Engineering", value: "Engineering" },
      { label: "Healthcare", value: "Healthcare" },
      { label: "Education", value: "Education" },
      { label: "Information Technology", value: "Information Technology" },
      // { label: "Nonprofit Management", value: "Nonprofit Management" },
      {
        label: "Trades/Labor/Manufacturing",
        value: "Trades/Labor/Manufacturing",
      },
    ];

    const statuses = [
      { label: "YES", value: true },
      { label: "NO", value: false },
    ];

    const referralSources = [
      { label: "Avenue Scholars", value: "Avenue Scholars" },
      { label: "School Staff(Teachers, Counselors, Administration, etc.)", value: "School Staff(Teachers, Counselors, Administration, etc.)" },
      { label: "The Avenue Scholars Interns Team", value: "The Avenue Scholars Interns Team" },
      { label: "Web Search", value: "Web Search" },
      { label: "Metropolitan Community College", value: "Metropolitan Community College" },
      { label: " Other(please specify)", value: " Other(please specify)" },
    ];

    const currentType = (jwt_decode(localStorage.getItem("token") as string) as any).AccountType;

    return (
      <React.Fragment>
        {currentType === "2" ? (
          <Button
            label="Back To List"
            onClick={() => {
              this.props.history.push(`/internships/student`);
            }}
          />
        ) : (
          <Button
            label="Back"
            onClick={() => {
              this.props.history.push(`/internships/${this.internship.id}`);
            }}
          />
        )}

        <Card title="" subTitle="" className="preview-card">

                {this.state.internship.employerId && (                 
                    <img height="50" src={`./api/employers/${this.state.internship.employerId}/logo`}></img>
                )}

          <br />
          <br />
          <h1>{this.state.internship.employer ? this.state.internship.employer.employerName : ""}</h1>
          <br />
          <label className="preview-label">Internship Title:</label>&nbsp;
          {this.state.internship.internshipTitle}
          <br />
          <br />
          <label className="preview-label">Internship Domain:</label>&nbsp;
          {this.state.internship.internshipDomain}
          <br />
          <br />
          <label className="preview-label">Who We Are:</label>&nbsp;
          <span dangerouslySetInnerHTML={{ __html: this.state.internship.whoWeAre ? this.state.internship.whoWeAre : "" }} />
          <br />
          <br />
          <label className="preview-label">A Day in the Life of Our Interns:</label>&nbsp;
          <span dangerouslySetInnerHTML={{ __html: this.state.internship.jobDescription ? this.state.internship.jobDescription : "" }} />
          <br />
          <br />
          <label className="preview-label">A Typical Schedule Looks Like:</label>&nbsp;
          <span dangerouslySetInnerHTML={{ __html: this.state.internship.workSchedule ? this.state.internship.workSchedule : "" }} />
          <br />
          <br />
          <label className="preview-label">You'll Be Successful Here When You:</label>&nbsp;
          <span dangerouslySetInnerHTML={{ __html: this.state.internship.preferredSkills ? this.state.internship.preferredSkills : "" }} />
          <br />
          <br />
          <label className="preview-label">You Should Wear:</label>&nbsp;
          <span dangerouslySetInnerHTML={{ __html: this.state.internship.dressCodeRequirements ? this.state.internship.dressCodeRequirements : "" }} />
          <br />
          <br />
          <label className="preview-label">To Work Here, You Need:</label>&nbsp;
          <span dangerouslySetInnerHTML={{ __html: this.state.internship.eligibilityRequirements ? this.state.internship.eligibilityRequirements : "" }} />
          <br />
          <br />
          <label className="preview-label">What You Can Expect From Us:</label>&nbsp;
          <span dangerouslySetInnerHTML={{ __html: this.state.internship.whatYouCanExpect ? this.state.internship.requiredTools : "" }} />
          <br />
          <br />
          
          
          {/* <label className="preview-label">Required Tools:</label>&nbsp;
          <span dangerouslySetInnerHTML={{ __html: this.state.internship.requiredTools ? this.state.internship.requiredTools : "" }} />
          <br />
          <br /> */}
          <label className="preview-label">Pay Per Hour Minimum:</label>&nbsp; ${this.state.internship.payPerHour}
          <br />
          <br />
          <label className="preview-label">Pay Per Hour Maximum:</label>&nbsp; ${this.state.internship.payPerHourMax}
          <br />
          <br />
          <label className="preview-label">Number Of Positions Available:</label>
          &nbsp;
          {this.state.internship.numberOfPositionsAvailable}
          <br />
          <br />
          <label className="preview-label">Internship Location:</label>
          <br />
          {this.state.internship.internshipLocationAddress1}
          <br />
          {/* {this.internship.internshipLocationAddress2 ? this.internship.internshipLocationAddress2 : ''}<br /> */}
          {this.state.internship.internshipLocationCity}, {this.state.internship.internshipLocationState} {this.state.internship.internshipLocationZip}
          <br />
          <br />
          {this.state.internship.externalJobApplicationLink && this.state.internship.externalJobApplicationLink.length > 0 ? (
            <div>
              <label className="preview-label">
                This employer requires an additional application be completed at the following link. (Will open in a new window):
              </label>
              <br />
              <a target="_blank" href={this.state.internship.externalJobApplicationLink}>
                Click to apply
              </a>
            </div>
          ) : (
            <div></div>
          )}
          <br />
          <br />
          <hr />
          {/* <label>How did you hear about this opportunity?:</label>
          <br />
          <MultiSelect
            ref={this.myRef}
            //optionLabel="howDidYouHear"
            value={this.state.howDidYouHear}
            options={referralSources}
            onChange={(e) => this.setState({ howDidYouHear: e.value })}
          />
          <br />
          <label>Other:</label>
          <Editor
            style={{ height: "320px" }}
            value={this.state.howDidYouHearOther}
            onTextChange={(e) => this.setState({ howDidYouHearOther: e.htmlValue as string })}
          />
          <br />
          <br /> */}
          <label>What interests you about this position?:</label>
          <div className="guideText">Discuss why you are interested in a career in the field you have chosen and why this position is
          a good fit for you. Show that you’ve done your research and demonstrate you know about the
          company by describing what draws you to that specific company.</div>
          <br />
          <Editor
            style={{ height: "320px" }}
            value={this.state.whatInterestsYou}
            onTextChange={(e) => this.setState({ whatInterestsYou: e.htmlValue as string })}
          />
          <br />
          <label>
            Why would you be successful in this position?:
          </label>
          <div className="guideText">Share what experiences you have had that have prepared you for this internship. This can
            include previous work experience, classes you have taken, job shadows you have been on, or
            other accomplishments. You don’t need experience in the field you are applying for. Show
            employers that you are passionate, curious, and motivated to learn.</div>
          <br />
          <Editor
            style={{ height: "320px" }}
            value={this.state.whyStrongestCandidate}
            onTextChange={(e) => this.setState({ whyStrongestCandidate: e.htmlValue as string })}
          />
          <br />
          {/* <label>What are your immediate career goals?:</label>
          <br />
          <Editor
            style={{ height: "320px" }}
            value={this.state.immediateCareerGoals}
            onTextChange={(e) => this.setState({ immediateCareerGoals: e.htmlValue as string })}
          />
          <br /> */}
          <label>What do you want your life to look like in 5 years?:</label>
          <div className="guideText">This is the place to show what is important to you. What are you looking for in a career? What
            sort of position will keep you engaged and motivated? This question also gives you the
            opportunity to showcase that you know how to set a goal and make a plan to achieve it.</div>
          <br />
          <Editor
            style={{ height: "320px" }}
            value={this.state.longTermCareerGoals}
            onTextChange={(e) => this.setState({ longTermCareerGoals: e.htmlValue as string })}
          />
          <br />
          <label>The expectations for Avenue Scholars Interns are:</label>
          <ul>
                    <li>I will respond to emails and phone calls from the Business during the interview process.</li>
                    <li>I will be able start my internship in June 2025.</li>
                    <li>I will provide my own transportation to and from my internship.</li>
                    <li>I will work 20-40 hours per week during the Summer.</li>
                    <li>I will work 10-20 hours per week during the School year (if applicable).</li>
                    <li>I will respond in a timely manner to my High School Career Coach and Supervisor.</li>
          </ul>
          
          <label>
            Do you agree to meet these expectations if you are offered an internship?
          </label>
          <br />
          {/* <InputSwitch
            checked={this.state.canSatisfyRequirements}
            onChange={(e) => this.setState({ canSatisfyRequirements: e.value })}
          /> */}
          {/* <Dropdown
            value={this.state.canSatisfyRequirements}
            options={statuses}
            onChange={(e) => {
              this.setState({ canSatisfyRequirements: e.value });
            }}
            placeholder="Select YES or NO"
          /> */}
          Yes:{" "}
          <RadioButton
            name="canSatisfyRequirements"
            onChange={(e) => this.setState({ canSatisfyRequirements: true })}
            checked={this.state.canSatisfyRequirements === true}
          />
          &nbsp; No:
          <RadioButton
            name="canSatisfyRequirements"
            onChange={(e) => this.setState({ canSatisfyRequirements: false })}
            checked={this.state.canSatisfyRequirements === false}
          />
          <br />
          <br />
          {this.state.canSatisfyRequirements === false ? (
            <div>
              <label>If you answered "no" to the previous question, please explain below.:</label>
              <br />
              <Editor
                style={{ height: "320px" }}
                value={this.state.canSatisfyRequirementsExplaination}
                onTextChange={(e) =>
                  this.setState({
                    canSatisfyRequirementsExplaination: e.htmlValue as string,
                  })
                }
              />
            </div>
          ) : (
            <div></div>
          )}
          <br />
          <br />
                <Button disabled={ this.state.isButtonClicked}
            label="Next - Add Resume"
            onClick={() => {
                
              if (this.state.id === 0) {
                  this.props.createApplication(this.state);
                  this.setState({ isButtonClicked: true })
              } else {
                  this.props.updateApplication(this.state);
                  this.setState({ isButtonClicked: true })
              }
              //this.props.history.push(`/internships/student`);
            }}
          />
        </Card>
      </React.Fragment>
    );
  }
}

export default connect((state: ApplicationState) => state.application, ApplicationsStore.actionCreators)(InternshipApply as any);
