import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ApplicationState } from "../../../store";
import * as EmployerStore from "../../../store/employer";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { IEmployer } from "../../../models/IEmployer";
import { Card } from "primereact/card";
import { ToastContainer, toast } from "react-toastify";
import { FileUpload } from "primereact/fileupload";
import "react-toastify/dist/ReactToastify.css";
import jwt_decode from "jwt-decode";
import { InputSwitch } from "primereact/inputswitch";
import { AccountType } from "../../../models/enums/accountType";
import { useEffect, useState } from "react";

type EmployerEditProps = EmployerStore.EmployerState & typeof EmployerStore.actionCreators & RouteComponentProps<{}>;

function EmployerEdit(props: EmployerEditProps) {
  const [currentEmployer, setCurrentEmployer] = useState(props.currentEmployer);
  const [hasUploaded, setHasUploaded] = useState(true);


  useEffect(() => {
    const currentEmployerId = (props.match.params as any).employerId;
    props.clearCurrentEmployer();
    setCurrentEmployer(props.currentEmployer);
    if (currentEmployerId > 0) {
      props.getEmployer(currentEmployerId);
    }
  }, []);

  useEffect(() => {
      setCurrentEmployer(props.currentEmployer as IEmployer);
  }, [props.currentEmployer]);



  const notify: any = () => toast.success("Employer Saved!");
  const onUpload: any = () => {
    setHasUploaded(false);
    setHasUploaded(true);
  };
  const currentType = (jwt_decode(localStorage.getItem("token") as string) as any).AccountType;


  return (
    <React.Fragment>
      {currentType === "0" ? (
        <Button
          label="Back To List"
          onClick={() => {
            props.history.push(`/employers`);
          }}
        />
      ) : (
        <div></div>
      )}

      <Card title="Edit Employer" subTitle="">
        <label>Employer Name:</label>
        <br />
        <InputText
          name="employerName"
          value={currentEmployer?.employerName}
          onChange={(e) => setCurrentEmployer({ ...currentEmployer, employerName: (e.target as unknown as HTMLTextAreaElement).value } as IEmployer)}
        />
        <br />
        <br />

        <label>Website:</label>
        <br />
        <InputText
          name="website"
          value={currentEmployer?.website}
          onChange={(e) => setCurrentEmployer({ ...currentEmployer, website: (e.target as unknown as HTMLTextAreaElement).value } as IEmployer)}
        />
        <br />
        <br />

              {currentEmployer?.id && (
                  <div>
                      <label>Logo:</label>
                      <br />
                      <FileUpload name="logo" url={`./api/employers/logo/upload/${currentEmployer?.id}`} onUpload={onUpload}></FileUpload>
                      <br />
                      <br />
                      {hasUploaded && (
                          <img height="50" src={`./api/employers/${currentEmployer?.id}/logo`}></img>
                      )}
                  </div>
              )}
        <br />
        <br />

        <label>Address 1:</label>
        <br />
        <InputText
          name="address1"
          value={currentEmployer?.address1}
          onChange={(e) => setCurrentEmployer({ ...currentEmployer, address1: (e.target as unknown as HTMLTextAreaElement).value } as IEmployer)}
        />
        <br />
        <br />

        <label>Address 2:</label>
        <br />
        <InputText
          name="address2"
          value={currentEmployer?.address2}
          onChange={(e) => setCurrentEmployer({ ...currentEmployer, address2: (e.target as unknown as HTMLTextAreaElement).value } as IEmployer)}
        />
        <br />
        <br />

        <label>City:</label>
        <br />
        <InputText name="city" value={currentEmployer?.city} onChange={(e) => setCurrentEmployer({ ...currentEmployer, city: (e.target as unknown as HTMLTextAreaElement).value } as IEmployer)} />
        <br />
        <br />

        <label>State:</label>
        <br />
        <InputText name="currentEmployer?" value={currentEmployer?.state} onChange={(e) => setCurrentEmployer({ ...currentEmployer, state: (e.target as unknown as HTMLTextAreaElement).value } as IEmployer)} />
        <br />
        <br />

        <label>ZIP:</label>
        <br />
        <InputText name="zip" value={currentEmployer?.zip} onChange={(e) => setCurrentEmployer({ ...currentEmployer, zip: (e.target as unknown as HTMLTextAreaElement).value } as IEmployer)} />
        <br />
        <br />

        <label>Main Contact:</label>
        <br />
        <InputText
          name="mainContactName"
          value={currentEmployer?.mainContactName}
          onChange={(e) => setCurrentEmployer({ ...currentEmployer, mainContactName: (e.target as unknown as HTMLTextAreaElement).value } as IEmployer)}
        />
        <br />
        <br />

        <label>Main Contact Phone:</label>
        <br />
        <InputText
          name="mainContactPhone"
          value={currentEmployer?.mainContactPhone}
          onChange={(e) => setCurrentEmployer({ ...currentEmployer, mainContactPhone: (e.target as unknown as HTMLTextAreaElement).value } as IEmployer)}
        />
        <br />
        <br />

        <label>Main Contact Email:</label>
        <br />
        <InputText
          name="mainContactEmail"
          value={currentEmployer?.mainContactEmail}
          onChange={(e) => setCurrentEmployer({ ...currentEmployer, mainContactEmail: (e.target as unknown as HTMLTextAreaElement).value } as IEmployer)}
        />
        <br />
        <br />

        {(jwt_decode(localStorage.getItem("token") as string) as any).AccountType == AccountType.Admin ? (
          <div>
            {/*<label>Auto-Approve Users:</label>*/}
            {/*<br />*/}
            {/*<InputSwitch checked={currentEmployer?.autoApproveNewUsers} onChange={(e) => setCurrentEmployer({ ...currentEmployer, autoApproveNewUsers: e.value } as IEmployer)} />*/}
            {/*<br />*/}
            {/*<br />*/}

            {/*<label>Auto-Approve Users From This Domain:</label>*/}
            {/*<br />*/}
            {/*<InputText*/}
            {/*  name="mainContactEmail"*/}
            {/*  value={currentEmployer?.autoApproveEmailDomain}*/}
            {/*  onChange={(e) => setCurrentEmployer({ ...currentEmployer, autoApproveEmailDomain: (e.target as unknown as HTMLTextAreaElement).value } as IEmployer)}*/}
            {/*/>*/}
            {/*<br />*/}
            {/*<br />*/}

            {/*<label>Auto-Approve Internship Postings:</label>*/}
            {/*<br />*/}
            {/*<InputSwitch checked={currentEmployer?.autoApproveNewInternships} onChange={(e) => setCurrentEmployer({ ...currentEmployer, autoApproveNewInternships: e.value } as IEmployer)} />*/}
            {/*<br />*/}
            {/*<br />*/}
          </div>
        ) : (
          ""
        )}

        <Button
          label="Save"
          onClick={() => {
            if (currentEmployer?.id === 0) {
              props.createEmployer(currentEmployer);
            } else {
              props.updateEmployer(currentEmployer as IEmployer);
            }
            notify();
          }}
        />
        {(currentEmployer?.id ?? 0) > 0 &&
          (jwt_decode(localStorage.getItem("token") as string) as any).AccountType ==
          AccountType.Admin && (
            <Button
              label="Archive"
              className="p-button-danger"
              onClick={() => {
                props.deleteEmployer(currentEmployer?.id ?? 0);
                props.history.push(`/employers`);
              }}
            />
          )}
      </Card>
    </React.Fragment>
  );
}


export default connect((state: ApplicationState) => state.employer, EmployerStore.actionCreators)(EmployerEdit as any);
