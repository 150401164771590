import React, { FunctionComponent } from "react";
import { Route, Switch } from "react-router-dom";
import { APP_ROUTES } from "./app.routing.constants";
import Counter from "../../components/Counter";
import Home from "../../components/Home";
import EmployerEdit from "../../pages/employer/employerEdit/employerEdit";
import StudentEdit from "../../pages/student/studentEdit/studentEdit";
import internshipList from "../../pages/employer/internshipList/internshipList";
import internshipEdit from "../../pages/employer/internshipEdit/internshipEdit";
import adminSettings from "../../pages/admin/settings/adminSettings";
import internshipView from "../../pages/employer/internshipView/internshipView";
import studentInternshipList from "../../pages/student/internshipList/studentInternshipList";
import employerList from "../../pages/admin/employerList/employerList";
import userList from "../../pages/admin/userList/userList";
import userEdit from "../../pages/admin/userEdit/userEdit";
import internshipApply from "../../pages/employer/internshipApply/internshipApply";
import applicationList from "../../pages/applications/applicationList/applicationList";
import studentEmailBlast from "../../pages/admin/settings/studentEmailBlast";
import employerEmailBlast from "../../pages/admin/settings/employerEmailBlast";
import applicationView from "../../pages/applications/viewApplication/applicationView";
import reportList from "../../pages/admin/reports/reportList";
import studentSchoolCounts from "../../pages/admin/reports/studentSchoolCounts";
import applicantsPerRole from "../../pages/admin/reports/applicantsPerRole";
import studentsInRoles from "../../pages/admin/reports/studentsInRoles";
import yoyPartnerPerformance from "../../pages/admin/reports/yoyPartnerPerformance";
import landing from "../../pages/admin/settings/landing";
import attachResume from "../../pages/employer/internshipApply/attachResume";
import studentInformation from "../../pages/admin/reports/studentInformation";
import studentApplications from "../../pages/admin/reports/studentApplications";
import cohortList from "../../pages/admin/cohorts/cohortList";
import cohortEdit from "../../pages/admin/cohorts/cohortEdit";

export const AppRouting: FunctionComponent = () => (
  <Switch>
    <Route exact path={APP_ROUTES.COUNTER} component={Counter} />
    <Route exact path={APP_ROUTES.DASHBOARD} component={Home} />
    <Route exact path={APP_ROUTES.USER_LIST} component={userList} />
    <Route exact path={APP_ROUTES.USER_EDIT} component={userEdit} />
    <Route exact path={APP_ROUTES.COHORT_LIST} component={cohortList} />
    <Route exact path={APP_ROUTES.COHORT_EDIT} component={cohortEdit} />
    <Route exact path={APP_ROUTES.ADMIN_SETTINGS} component={adminSettings} />
    <Route exact path={APP_ROUTES.LANDING} component={landing} />
    <Route exact path={APP_ROUTES.ADMIN_STUDENT_EMAIL_BLAST} component={studentEmailBlast} />
    <Route exact path={APP_ROUTES.ADMIN_EMPLOYER_EMAIL_BLAST} component={employerEmailBlast} />
    <Route exact path={APP_ROUTES.EMPLOYER_LIST} component={employerList} />
    <Route exact path={APP_ROUTES.EMPLOYER_EDIT} component={EmployerEdit} />
    <Route exact path={APP_ROUTES.STUDENT_EDIT} component={StudentEdit} />
    <Route exact path={APP_ROUTES.STUDENT_INTERNSHIP_LIST} component={studentInternshipList} />
    <Route exact path={APP_ROUTES.INTERNSHIP_LIST} component={internshipList} />
    <Route exact path={APP_ROUTES.INTERNSHIP_EDIT} component={internshipEdit} />
    <Route exact path={APP_ROUTES.INTERNSHIP_VIEW} component={internshipView} />
    <Route exact path={APP_ROUTES.INTERNSHIP_APPLY} component={internshipApply} />
    <Route exact path={APP_ROUTES.INTERNSHIP_APPLY_EDIT} component={internshipApply} />
    <Route exact path={APP_ROUTES.APPLICATION_LIST} component={applicationList} />
    <Route exact path={APP_ROUTES.APPLICATION_VIEW} component={applicationView} />
    <Route exact path={APP_ROUTES.APPLICATION_ATTACH_RESUME} component={attachResume} />
    <Route exact path={APP_ROUTES.STUDENT_APPLICATION_LIST} component={applicationList} />
    <Route exact path={APP_ROUTES.EMPLOYER_APPLICATION_LIST} component={applicationList} />
    <Route exact path={APP_ROUTES.REPORT_LIST} component={reportList} />
    <Route exact path={APP_ROUTES.REPORT_STUDENT_SCHOOL_COUNTS} component={studentSchoolCounts} />
    <Route exact path={APP_ROUTES.REPORT_APPLICANTS_PER_ROLE} component={applicantsPerRole} />
    <Route exact path={APP_ROUTES.REPORT_STUDENTS_IN_ROLES} component={studentsInRoles} />
    <Route exact path={APP_ROUTES.REPORT_STUDENT_INFORMATION} component={studentInformation} />
    <Route exact path={APP_ROUTES.REPORT_YOY_PARTNER_PERFORMANCE} component={yoyPartnerPerformance} />
    <Route exact path={APP_ROUTES.REPORT_STUDENT_APPLICATIONS} component={studentApplications} />
  </Switch>
);
