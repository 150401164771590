import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ApplicationState } from "../store";
import * as UserStore from "../store/user";
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import "./Navigation.scss";
import { AccountType } from "../models/enums/accountType";
import jwt_decode from "jwt-decode";
import { useEffect, useState } from "react";
import { IUser } from "../models/IUser";

type NavigationProps = UserStore.UserState & typeof UserStore.actionCreators & RouteComponentProps<{}>;

function Navigation(props: NavigationProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [currentType, setCurrentType] = useState(null);
  const [currentStudentId, setCurrentStudentId] = useState(null);
  const [currentEmployerId, setCurrentEmployerId] = useState(null);
  const [currentUserId, setCurrentUserId] = useState(null);
  const [currentUsername, setCurrentUsername] = useState("");
  const [currentUser, setCurrentUser] = useState({} as IUser);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      setCurrentStudentId((jwt_decode(localStorage.getItem("token") as string) as any).StudentId);
      setCurrentEmployerId((jwt_decode(localStorage.getItem("token") as string) as any).EmployerId);
      setCurrentUserId((jwt_decode(localStorage.getItem("token") as string) as any).UserId);
      setCurrentUsername((jwt_decode(localStorage.getItem("token") as string) as any).unique_name);
      setCurrentType((jwt_decode(localStorage.getItem("token") as string) as any).AccountType);
      setCurrentType((jwt_decode(localStorage.getItem("token") as string) as any).AccountType);
    }
  }, []);

  useEffect(() => {
    setCurrentUser(props.currentUser);
  }, [props.currentUser]);

  useEffect(() => {
    if (props.isLoggedIn) {
      props.getCurrentUser((jwt_decode(localStorage.getItem("token") as string) as any).UserId);
    }
  }, [props.isLoggedIn]);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <React.Fragment>
      <header>
        <Navbar className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow mb-3" light>
          <Container>
            <NavbarBrand tag={Link} to="/landing">
              <img style={{ width: "300px" }} src="/images/logo_wide.png" />
            </NavbarBrand>
            <NavbarToggler onClick={toggle} className="mr-2" />
            <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={isOpen} navbar>
              <ul className="navbar-nav flex-grow">
                {/* <NavItem>
                                        <NavLink tag={Link} className="text-dark" to="/useradmin">User Admin</NavLink>
                                    </NavItem> */}

                {!props.isLoggedIn && (
                  <div className="navItemList">
                    <NavItem>
                      <NavLink tag={Link} className="text-dark" to="/">
                        Home
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink tag={Link} className="text-dark" to="/login">
                        Login
                      </NavLink>
                    </NavItem>
                  </div>
                )}
                {props.isLoggedIn && (
                  <React.Fragment>
                    {currentUser.accountType === AccountType.Admin && (
                      <div className="navItemList">
                        <NavLink tag={Link} className="text-dark" to="/landing">
                          Home
                        </NavLink>
                        <NavItem>
                          <NavLink tag={Link} className="text-dark" to="/internships">
                            Internships
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink tag={Link} className="text-dark" to="/employers">
                            Employers
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink tag={Link} className="text-dark" to="/users">
                            Users
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink tag={Link} className="text-dark" to={`/applications`}>
                            Applications
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink tag={Link} className="text-dark" to={`/reports`}>
                            Reports
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink tag={Link} className="text-dark" to={`/cohorts`}>
                            Cohorts
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink tag={Link} className="text-dark" to={`/adminsettings`}>
                            Admin Settings
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            tag={Link}
                            className="text-dark"
                            to="/login"
                            onClick={() => {
                              props.clearToken();
                            }}
                          >
                            Logout {currentUser.username}
                          </NavLink>
                        </NavItem>
                      </div>
                    )}

                    {currentUser.accountType === AccountType.Employer && (
                      <div className="navItemList">
                        <NavLink tag={Link} className="text-dark" to="/landing">
                          Home
                        </NavLink>
                        <NavItem>
                          <NavLink tag={Link} className="text-dark" to="/internships">
                            Internships
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink tag={Link} className="text-dark" to={`/employers/${currentUser.employerId}/applications`}>
                            Applications
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink tag={Link} className="text-dark" to={`/employers/${currentUser.employerId}`}>
                            Edit Employer
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            tag={Link}
                            className="text-dark"
                            to="/login"
                            onClick={() => {
                              props.clearToken();
                            }}
                          >
                            Logout {currentUser.username}
                          </NavLink>
                        </NavItem>
                      </div>
                    )}

                    {currentUser.accountType === AccountType.District && (
                      <div className="navItemList">
                        <NavLink tag={Link} className="text-dark" to="/landing">
                          Home
                        </NavLink>
                        <NavItem>
                          <NavLink tag={Link} className="text-dark" to="/internships/student">
                            Internships
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink tag={Link} className="text-dark" to="/users">
                            Users
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink tag={Link} className="text-dark" to={`/applications`}>
                            Applications
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink tag={Link} className="text-dark" to={`/reports`}>
                            Reports
                          </NavLink>
                        </NavItem>
                        <NavItem>
                         <NavLink tag={Link} className="text-dark" to={`/users/edit/${currentUser.id}`}>
                            Edit User
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            tag={Link}
                            className="text-dark"
                            to="/login"
                            onClick={() => {
                              props.clearToken();
                            }}
                          >
                            Logout {currentUser.username}
                          </NavLink>
                        </NavItem>
                      </div>
                    )}

                    {currentUser.accountType === AccountType.Student && (
                      <div className="navItemList">
                        <NavLink tag={Link} className="text-dark" to="/landing">
                          Home
                        </NavLink>
                        <NavItem>
                          <NavLink tag={Link} className="text-dark" to="/internships/student">
                            Available Internships
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink tag={Link} className="text-dark" to={`/students/${currentUser.studentId}/applications`}>
                            My Applications
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink tag={Link} className="text-dark" to={`/students/${currentUser.studentId}`}>
                            Edit My Profile
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            tag={Link}
                            className="text-dark"
                            to="/login"
                            onClick={() => {
                              props.clearToken();
                            }}
                          >
                            Logout {currentUser.username}
                          </NavLink>
                        </NavItem>
                      </div>
                    )}
                  </React.Fragment>
                )}
              </ul>
            </Collapse>
          </Container>
        </Navbar>
      </header>
    </React.Fragment>
  );
}

export default connect((state: ApplicationState) => state.user, UserStore.actionCreators)(Navigation as any);
