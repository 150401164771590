import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ApplicationState } from "../../../store";
import * as StudentStore from "../../../store/student";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { ToastContainer, toast } from "react-toastify";
import { FileUpload } from "primereact/fileupload";
import "react-toastify/dist/ReactToastify.css";
import { IStudent } from "../../../models/IStudent";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from 'primereact/multiselect';
import { InputSwitch } from "primereact/inputswitch";
import { Checkbox } from "primereact/checkbox";
import { RadioButton } from "primereact/radiobutton";
import Axios from "axios";
import fileDownload from "js-file-download";
import { useEffect, useState } from "react";

type StudentEditProps = StudentStore.StudentState & typeof StudentStore.actionCreators & RouteComponentProps<{}>;

function StudentEdit(props: StudentEditProps) {
  const [currentStudent, setCurrentStudent] = useState(props.currentStudent);

  useEffect(() => {
    const currentStudentId = (props.match.params as any).studentId;
    props.clearCurrentStudent();
    props.getStudent(currentStudentId);
  }, []);

  useEffect(() => {
    setCurrentStudent(props.currentStudent);
  }, [props.currentStudent]);

  const notify: any = () => toast.success("Profile Saved!");
  const notifyResume: any = () => toast.success("Resume Saved!");
  const notifyRequiredFields: any = () => toast.error("Please fill in all required fields!");
  const onUpload: any = () => {
    setCurrentStudent({ ...currentStudent, hasResume: true } as IStudent);
    props.updateStudent({ ...currentStudent } as IStudent);
    setTimeout(() => {
      props.getStudent(currentStudent?.id ?? 0);
      notifyResume();
    }, 600);
  };

  function isFormComplete() {
    if (
      currentStudent?.firstName &&
      currentStudent?.lastName &&
      currentStudent?.email &&
      currentStudent?.phone &&
      currentStudent?.schoolId &&
      currentStudent?.gender &&
      currentStudent?.race &&
      //currentStudent?.freeOrReducedLunch &&
      currentStudent?.graduationYear
    ) {
      return true;
    }
    return false;
  }

  const genders = [
    { label: "Female", value: "Female" },
    { label: "Male", value: "Male" },
    { label: "Non-Binary", value: "Non-Binary" },
    { label: "Prefer not to answer", value: "Prefer not to answer" },
  ];

  const lunch = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
    { label: "I don't know", value: "I don't know" },
    { label: "Prefer not to answer", value: "Prefer not to answer" },
  ];

  const races = [
    { label: "Asian", value: "Asian" },
    { label: "Black or African American", value: "Black or African American" },
    { label: "Caucasian or White", value: "Caucasian or White" },
    { label: "Hispanic or Latinx", value: "Hispanic or Latinx" },
    { label: "Native American", value: "Native American" },
    { label: "Middle Eastern or North African", value: "Middle Eastern or North African" },
    { label: "Pacific Islander", value: "Pacific Islander" },
    //{ label: "Other", value: "Other" },
    { label: "Prefer not to answer", value: "Prefer not to answer" },
  ];

  const districts = [
    { label: "Ralston", value: "Ralston" },
    { label: "Millard", value: "Millard" },
    { label: "Blair", value: "Blair" },
    { label: "Omaha Public Schools", value: "Omaha Public Schools" },
    { label: "Papillion La-Vista", value: "Papillion La-Vista" },
    { label: "DC West", value: "DC West" },
    { label: "Westside", value: "Westside" },
    // { label: "Other", value: "Other" },
  ];

  const schools = [
    { label: "Ralston High School", value: 3 },
    // { label: "DC West High School", value: 4 },
    { label: "Westside High School", value: 5 },
    { label: "Millard South High School", value: 6 },
    { label: "Millard North High School", value: 8 },
    // { label: "Millard West High School", value: 7 },
    { label: "Blair High School", value: 9 },
    { label: "Omaha Benson High School", value: 10 },
    { label: "Omaha Bryan High School", value: 11 },
    { label: "Omaha North High School", value: 12 },
    { label: "Omaha Northwest High School", value: 13 },
    { label: "Omaha South High School", value: 14 },
    { label: "Papillion La-Vista High School", value: 15 },
    // { label: "Marian", value: 18 },
    // { label: "Duchesne", value: 17 },
    // { label: "Other", value: 16 },
  ];

  function downloadResume(id: number, filename: string) {
    Axios.get("./api/students/" + id + "/resume", {
      responseType: "blob",
    }).then((res) => {
      fileDownload(res.data, filename);
    });
  }

  return (
    <React.Fragment>
      <Card title="" subTitle="">
        <h2>Edit Student Profile</h2>
        <label>First Name:</label>
        <br />
        <InputText
          name="studentFirstName"
          value={currentStudent?.firstName}
          onChange={(e) => setCurrentStudent({ ...(currentStudent as IStudent), firstName: (e.target as unknown as HTMLTextAreaElement).value })}
        />
        <span className="required">*</span>
        <br />
        <br />
        <label>Last Name:</label>
        <br />
        <InputText
          name="studentLastName"
          value={currentStudent?.lastName}
          onChange={(e) => setCurrentStudent({ ...(currentStudent as IStudent), lastName: (e.target as unknown as HTMLTextAreaElement).value })}
        />{" "}
        <span className="required">*</span>
        <br />
        <br />
        <label>Email Address (select an address that can accept messages from employers):</label>
        <br />
        <InputText
          name="studentEmail"
          value={currentStudent?.email}
          onChange={(e) => setCurrentStudent({ ...(currentStudent as IStudent), email: (e.target as unknown as HTMLTextAreaElement).value })}
        />
        <span className="required">*</span>
        <br />
        <br />
        <label>Phone Number:</label>
        <br />
        <InputText
          name="studentPhone"
          value={currentStudent?.phone}
          onChange={(e) => setCurrentStudent({ ...(currentStudent as IStudent), phone: (e.target as unknown as HTMLTextAreaElement).value })}
        />
        <span className="required">*</span>
        {/* <br />
        <br />
        <label>School District:</label>
        <br />
        <Dropdown
          value={currentStudent?.schoolDistrict}
          options={districts}
          onChange={(e) => setCurrentStudent({ ...(currentStudent as IStudent), schoolDistrict: e.value })}
          placeholder="Select a School District"
        />
        <span className="required">*</span> */}
        <br />
        <br />
        <label>High School:</label>
        <br />
        <Dropdown
          value={currentStudent?.schoolId}
          options={schools}
          onChange={(e) => setCurrentStudent({ ...(currentStudent as IStudent), schoolId: e.value })}
          placeholder="Select a High School"
        />
        <span className="required">*</span>
        <br />
        <br />
        <label>Gender (This will not be shown to prospective employers):</label>
        <br />
        <Dropdown
          value={currentStudent?.gender}
          options={genders}
          onChange={(e) => setCurrentStudent({ ...(currentStudent as IStudent), gender: e.value })}
          placeholder="Select a Gender"
        />
        <span className="required">*</span>
        <br />
        <br />
        <label>Race (This will not be shown to prospective employers):</label>
        <br />
        {/* <Dropdown
          value={currentStudent?.race}
          options={races}
          onChange={(e) => setCurrentStudent({ ...(currentStudent as IStudent), race: e.value })}
          placeholder="Select a Race"
        />
        <br /> */}
        <MultiSelect optionLabel="label" optionValue="value" value={currentStudent?.race} options={races} onChange={(e) => setCurrentStudent({ ...(currentStudent as IStudent), race: e.value })} />
        <span className="required">*</span>
        {/*<label>I am an Avenue Scholar:</label>*/}
        {/*<br />*/}
        {/* <InputSwitch checked={currentStudent?.isAvenueScholar} onChange={(e) => this.setState({ isAvenueScholar: e.value })} /> */}
        {/*<Checkbox*/}
        {/*  onChange={(e) => setCurrentStudent({ ...(currentStudent as IStudent), isAvenueScholar: !currentStudent?.isAvenueScholar })}*/}
        {/*  checked={currentStudent?.isAvenueScholar}*/}
        {/*></Checkbox>*/}
        {/* <br /><br />
          <label>I am an MCC Career Academy student:</label><br /> */}
        {/* <InputSwitch checked={currentStudent?.isMCCCareerAcademy} onChange={(e) => this.setState({ isMCCCareerAcademy: e.value })} /> */}
        {/* <Checkbox onChange={(e) => this.setState({ isMCCCareerAcademy: !currentStudent?.isMCCCareerAcademy })} checked={currentStudent?.isMCCCareerAcademy}></Checkbox> */}
        {/*<label>*/}
        {/*  Are you currently or will you be enrolled in a school sponsored MCC Academy?:*/}
        {/*</label>*/}
        {/*<br />*/}
        {/* <InputSwitch checked={currentStudent?.isInterestedInMCC} onChange={(e) => this.setState({ isInterestedInMCC: e.value })} />*/}{/*
        */}{/*  <Checkbox onChange={(e) => this.setState({ isInterestedInMCC: !currentStudent?.isInterestedInMCC })} checked={currentStudent?.isInterestedInMCC}></Checkbox> */}
        {/*Yes:{" "}*/}
        {/*<RadioButton*/}
        {/*  name="isInterestedInMCC"*/}
        {/*  onChange={(e) => setCurrentStudent({ ...(currentStudent as IStudent), isInterestedInMCC: true })}*/}
        {/*  checked={currentStudent?.isInterestedInMCC === true}*/}
        {/*/>*/}
        {/*&nbsp; No:*/}
        {/*<RadioButton*/}
        {/*  name="isInterestedInMCC"*/}
        {/*  onChange={(e) => setCurrentStudent({ ...(currentStudent as IStudent), isInterestedInMCC: false })}*/}
        {/*  checked={currentStudent?.isInterestedInMCC === false}*/}
        {/*/>*/}
        {/* <br />*/}{/*
        <br />
        <label>Do you qualify for free or reduced lunch? (This will not be shown to prospective employers)</label>
        <br />
        <Dropdown
          value={currentStudent?.freeOrReducedLunch}
          options={lunch}
          onChange={(e) => setCurrentStudent({ ...(currentStudent as IStudent), freeOrReducedLunch: e.value })}
          placeholder="Select an answer"
        />
        <span className="required">*</span> */}
        <br />
        <br />
        <label>Graduation Year:</label>
        <br />
        <InputNumber
          min={0}
          max={2050}
          useGrouping={false}
          name="studentHighSchool"
          value={currentStudent?.graduationYear}
          onChange={(e) => setCurrentStudent({ ...(currentStudent as IStudent), graduationYear: e.value })}
        />
        <span className="required">*</span>
        <br />
        <br />
        <label>Upload a Resume (Word or PDF Format):</label>
        <br />
        <FileUpload
          auto={true}
          name="logo"
          url={`./api/students/resume/upload/${currentStudent?.id}`}
          //onBeforeUpload={beforeUpload}
          onUpload={onUpload}
        ></FileUpload>
        <br />
        {currentStudent?.hasResume ? (
          <div>
            <label>Current Resume:</label>
            <br />
            <a
              // target="_blank"
              // href={`./api/students/${currentStudent?.id}/resume`}
              style={{ cursor: "pointer", textDecoration: "underline", color: "blue" }}
              onClick={() => downloadResume(currentStudent?.id, currentStudent?.resumeFilename)}
            >
              <img width="40px" src="/images/document.png"></img> Download Preview
            </a>
            {/* <button onClick={() => downloadResume(currentStudent?.id, currentStudent?.resumeFilename)}>DirectDL</button> */}
            <br />
            <br />
            <br />
            <br />
          </div>
        ) : (
          ""
        )}
        <Button
          label="Save"
          onClick={() => {
            if (isFormComplete()) {
              if (currentStudent?.id === 0) {
                props.createStudent(currentStudent);
              } else {
                props.updateStudent(currentStudent as IStudent);
              }
              notify();
            } else {
              notifyRequiredFields();
            }
          }}
        />
      </Card>
    </React.Fragment>
  );
}

export default connect((state: ApplicationState) => state.student, StudentStore.actionCreators)(StudentEdit as any);
